import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from '../../context/UserContext';
import ConditionalStack from '.';

export default function UserConditionalStack({children, toggle, ...props}) {
  const {currentUser} = useContext(UserContext);

  const condition = () => (toggle ? !currentUser?.loggedIn : currentUser?.loggedIn);

  return (
    <ConditionalStack condition={condition} {...props}>
      {children}
    </ConditionalStack>
  );
}

UserConditionalStack.defaultProps = {
  toggle: false,
};
UserConditionalStack.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.bool,
};

export const UserConditionalStackPlasmicData = {
  name: 'User Conditional Stack',
  props: {
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'text',
          value:
            'Turn OFF will hide the content, once the user logs in the content will be visible.',
        },
        {
          type: 'text',
          value: 'Turn ON will show the content, once the user logs in the content will hide.',
        },
      ],
    },
    toggle: {
      type: 'boolean',
      defaultValue: true,
      displayName: 'Show content',
    },
  },
  defaultStyles: {width: '100%', padding: '8px'},
  importPath: './components/ConditionalStack/UserConditionalStack',
  isDefaultExport: true,
};
