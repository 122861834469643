import React from 'react';
import PropTypes from 'prop-types';

export default function ConditionalStack({children, condition, ...props}) {
  if (condition()) {
    return <div {...props}>{children}</div>;
  }
  return null;
}

ConditionalStack.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.func.isRequired,
};
