import PropTypes from 'prop-types';
import {Header as RadixHeader, Trigger as RadixTrigger} from '@radix-ui/react-accordion';

export default function Header({children, ...props}) {
  return (
    <RadixHeader>
      <RadixTrigger {...props}>{children}</RadixTrigger>
    </RadixHeader>
  );
}

/** ******** */
/* METADATA
/** ******** */
Header.defaultProps = {
  children: null,
};
Header.propTypes = {
  children: PropTypes.node,
};

export const HeaderPlasmicData = {
  name: 'Accordion.Header',
  props: {
    children: {
      type: 'slot',
    },
  },
  defaultStyles: {
    width: '250px',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  importPath: './components/UI/Accordion/AccordionHeader',
  isDefaultExport: true,
};
