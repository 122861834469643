/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import {Content as RadixContent} from '@radix-ui/react-accordion';

export default function Content({children, className, ...props}) {
  return (
    <RadixContent
      className={`overflow-hidden [&[data-state="closed"]]:animate-close [&[data-state="open"]]:animate-open ${className}`}
      {...props}
    >
      {children}
    </RadixContent>
  );
}

/** ******** */
/* METADATA
/** ******** */
Content.defaultProps = {
  children: null,
  className: '',
};
Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const ContentPlasmicData = {
  name: 'Accordion.Content',
  props: {
    forceMount: {
      displayName: 'Force mount',
      type: 'boolean',
    },
    children: {
      type: 'slot',
    },
  },
  defaultStyles: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  importPath: './components/UI/Accordion/AccordionContent',
  isDefaultExport: true,
};
