import PropTypes from 'prop-types';
import {Root as RadixRoot} from '@radix-ui/react-accordion';
import plasmicTemplate from './plasmic-template';

export default function Root({children, collapsible, ...props}) {
  return (
    <RadixRoot collapsible={collapsible.toString()} {...props}>
      {children}
    </RadixRoot>
  );
}

/** ******** */
/* METADATA
/** ******** */
Root.defaultProps = {
  children: null,
  collapsible: false,
};
Root.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
};

export const RootPlasmicData = {
  name: 'Accordion',
  props: {
    type: {
      displayName: 'Type',
      type: 'choice',
      options: ['single', 'multiple'],
      defaultValue: 'multiple',
    },
    collapsible: {
      displayName: 'Collapsible',
      type: 'boolean',
      defaultValue: true,
    },
    disabled: {
      displayName: 'Disable',
      type: 'boolean',
    },
    children: {
      type: 'slot',
      allowedComponents: ['Accordion.Item'],
      // TEMPLATE
      defaultValue: plasmicTemplate,
    },
  },
  importPath: './components/UI/Accordion/AccordionRoot',
  isDefaultExport: true,
};
