import PropTypes from 'prop-types';
import {Item as RadixItem} from '@radix-ui/react-accordion';

export default function Item({children, ...props}) {
  return <RadixItem {...props}>{children}</RadixItem>;
}

/** ******** */
/* METADATA
/** ******** */
Item.defaultProps = {
  children: null,
};
Item.propTypes = {
  children: PropTypes.node,
};

export const ItemPlasmicData = {
  name: 'Accordion.Item',
  props: {
    value: {
      displayName: 'Value',
      type: 'string',
    },
    disabled: {
      displayName: 'Disable',
      type: 'boolean',
    },
    children: {
      type: 'slot',
      allowedComponents: ['Accordion.Header', 'Accordion.Content'],
    },
  },
  defaultStyles: {width: '250px'},
  importPath: './components/UI/Accordion/AccordionItem',
  isDefaultExport: true,
};
