import {useState, useEffect} from 'react';

/**
 * Custom hook to determine wether the client's browser is compatible with AVIF images.
 *
 * @param void .
 *
 * @returns {boolean} - true is supported by browser, false is not supported by browser
 */

const useAvifSupport = () => {
  const [avifSupported, setAvifSupported] = useState(true);
  useEffect(() => {
    const avif = new Image();
    avif.src =
      'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
    avif.onload = () => {
      setAvifSupported(true);
    };
    avif.onerror = () => {
      setAvifSupported(false);
    };
    return () => {
      // Clean up event listeners
      avif.onload = null;
      avif.onerror = null;
    };
  }, []);
  return avifSupported;
};

export default useAvifSupport;
