/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import Chevron from '../../../public/static/dropdown-arrow.svg';
import Arrow from '../../../public/static/right-pointer.svg';

const DEFAULT_ICON = {
  chevron: <StyledChevron />,
  arrow: <StyledArrow />,
};

export default function Icon({hidden, icon, children, className, ...props}) {
  if (hidden) return null;
  if (icon === 'custom') {
    return (
      <div className={className}>
        <StyledCustom {...props}>{children}</StyledCustom>
      </div>
    );
  }
  return <div className={className}>{DEFAULT_ICON[icon]}</div>;
}

function StyledCustom({transform, transition, children}) {
  const state = "[[data-state='open']_&]";

  // Build the animation:
  const animation = `${state}:rotate-${transform.rotate} ${state}:scale-${transform.scale} transition-all duration-${transition.duration} delay-${transition.delay} ease-${transition.ease}`;

  return <div className={`${animation}`}>{children}</div>;
}

function StyledChevron() {
  return (
    <Chevron className="ease-[cubic-bezier(0.87, 0, 0.13, 1)] transition-transform duration-300 [[data-state='open']_&]:-rotate-180" />
  );
}

function StyledArrow() {
  return (
    <Arrow className="ease-[cubic-bezier(0.87, 0, 0.13, 1)] invert transition-transform duration-300 [[data-state='open']_&]:rotate-90" />
  );
}

/** ******** */
/* METADATA
/** ******** */
Icon.defaultProps = {
  hidden: false,
  icon: 'chevron',
  className: '',
  children: null,
};
Icon.propTypes = {
  hidden: PropTypes.bool,
  icon: PropTypes.oneOf(['chevron', 'arrow', 'custom']),
  className: PropTypes.string,
  children: PropTypes.node,
};
Icon.displayName = 'AccordionIcon';

StyledCustom.propTypes = {
  transform: PropTypes.shape({
    rotate: PropTypes.number,
    scale: PropTypes.number,
  }),
  transition: PropTypes.shape({
    duration: PropTypes.number,
    delay: PropTypes.number,
    ease: PropTypes.string,
  }),
  children: PropTypes.node,
};
StyledCustom.defaultProps = {
  transform: {
    rotate: 0,
    scale: 100,
  },
  transition: {
    duration: 300,
    delay: 0,
    ease: ' out',
  },
  children: null,
};

export const IconPlasmicData = {
  name: 'Accordion.Icon',
  props: {
    hidden: {
      displayName: 'Hidde',
      type: 'boolean',
    },
    icon: {
      displayName: 'Choose icon',
      type: 'choice',
      options: ['chevron', 'arrow', 'custom'],
      defaultValue: 'arrow',
    },
    children: 'slot',
    transform: {
      displayName: 'On open transform',
      type: 'object',
      hidden: props => props.icon !== 'custom',
      defaultValue: {
        rotate: 0,
        scale: 100,
      },
    },
    transition: {
      displayName: 'On open transition',
      type: 'object',
      hidden: props => props.icon !== 'custom',
      defaultValue: {
        duration: 300,
        delay: 0,
        ease: 'out',
      },
    },
  },
  defaultStyles: {},
  importPath: './components/UI/Accordion/AccordionIcon',
  isDefaultExport: true,
};
